import React from "react";
import fuel from "../../assets/img/nick.jpg";
import oxiswap from "../../assets/img/l22.png"; 
import griffy from "../../assets/img/griffy.jpg"; 

const TestimonialCard = ({ author, role, quote, image }) => (
  <div className="bg-[#1a1a1a] p-5 rounded-lg border border-gray-700">
    <p className="text-gray-300 text-base mb-4">"{quote}"</p>
    <div className="flex items-center">
      <img src={image} alt={author} className="w-10 h-10 rounded-full mr-3 object-cover border border-gray-600" />
      <div>
        <p className="text-white font-medium">{author}</p>
        <p className="text-gray-500 text-xs">{role}</p>
      </div>
    </div>
  </div>
);

const MapToSafety = () => {
  return (
    <div className="bg-black py-16">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-white text-3xl md:text-4xl font-bold mt-2">
            Partner Comments
          </h2>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          <TestimonialCard
            author="Nick, Founder of Fuel Network"
            role="@IAmNickDodson"
            quote="Safe Edges helped us perform efficient and timely audits across the Fuel ecosystem. Their deep expertise in both Sway and Fuel has made them a serious player in the auditing space. The audit provided by Safe Edges Security allowed us to identify crucial improvements in our projects at Fuel Network. We highly recommend Safe Edges."
            image={fuel}
          />
          <TestimonialCard
            author="Fuelwill Founder"
            role="@oxiswap"
            quote="Safe Edges identified vulnerabilities in our contracts and their recommendations improved our security significantly."
            image={oxiswap}
          />
          <TestimonialCard
            author="Oswald, Founder of Griffy Market"
            role="@cmathemagician"
            quote="Impressive work by Safe Edges. Highly recommend their services!"
            image={griffy}
          />
        </div>
      </div>
    </div>
  );
};

export default MapToSafety;
