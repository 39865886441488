// React and Component Imports
import React, { useState } from 'react';

// Logo Imports
import logo1 from "../../assets/img/l1.png";
import logo2 from "../../assets/img/l2.png";
import logo3 from "../../assets/img/l3.png";
import logo4 from "../../assets/img/l4.png";
import logo5 from "../../assets/img/l5.png";
import logo6 from "../../assets/img/l6.png";
import logo7 from "../../assets/img/l7.png";
import logo8 from "../../assets/img/l8.svg";
import logo9 from "../../assets/img/l9.png";
import logo10 from "../../assets/img/l10.png";
import logo11 from "../../assets/img/l11.png";
import logo12 from "../../assets/img/l12.png";
import logo13 from "../../assets/img/l13.png";
import logo14 from "../../assets/img/l14.png";
import logo15 from "../../assets/img/l15.png";
import logo16 from "../../assets/img/l16.png";
import logo17 from "../../assets/img/l17.svg";
import logo18 from "../../assets/img/l18.png";
import logo19 from "../../assets/img/l19.svg";
import logo20 from "../../assets/img/l20.svg";
import logo21 from "../../assets/img/l21.png";
import logo22 from "../../assets/img/l22.png";
import logo23 from "../../assets/img/l23.svg";
import logo24 from "../../assets/img/l24.svg";
import logo25 from "../../assets/img/l25.png";

const TrustedBy = () => {
    const [showAllPartners, setShowAllPartners] = useState(false);
    const [showAllFuelChain, setShowAllFuelChain] = useState(false);

    const fuelChainData = [
        { id: 1, img: logo16, url: 'https://sprk.fi' },
        { id: 2, img: logo17, url: 'https://www.bako.global/' },
        { id: 3, img: logo23, url: 'https://swaylend.com' },
        { id: 4, img: logo20, url: 'https://redstone.finance' },
        { id: 5, img: logo9, url: 'https://hashcase.ca' },
        { id: 6, img: logo22, url: 'https://oxiswap.com' },
        { id: 7, img: logo18, url: 'https://griffy.app' },
        { id: 8, img: logo21, url: 'https://fluidprotocol.xyz/' },
        { id: 9, img: logo19, url: 'https://mira.ly' },
        { id: 11, img: logo25, url: 'https://lync.world/' },
        { id: 10, img: logo24, url: 'https://fuelet.app/' },
    ];

    const data = [
        { id: 1, img: logo1 },
        { id: 2, img: logo2 },
        { id: 3, img: logo3 },
        { id: 4, img: logo4 },
        { id: 5, img: logo5 },
        { id: 6, img: logo6 },
        { id: 7, img: logo7 },
        { id: 8, img: logo8, url: 'https://fuel.network' },
        { id: 9, img: logo9 },
        { id: 10, img: logo10 },
        { id: 11, img: logo11 },
        { id: 12, img: logo12 },
        { id: 13, img: logo13 },
        { id: 14, img: logo14 },
        { id: 15, img: logo15 },
    ];

    // Calculate items to show based on viewport size and showAll state
    const getVisibleItems = (items, showAll) => {
        if (showAll) return items;
        return items.slice(0, 10); // Show 2 rows (5 items per row)
    };

    return (
        <div className="py-6 bg-transparent">
            <div className="container mx-auto">
                <div className="text-center mb-4">
                    <p className="text-white text-xl font-semibold tracking-wide">
                        Trusted By
                    </p>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 gap-4 items-center justify-items-center">
                    {getVisibleItems(data, showAllPartners).map((item) => (
                        <div key={item.id} className="w-20 h-20 flex items-center justify-center">
                            <img 
                                src={item.img} 
                                alt={`Logo ${item.id}`} 
                                className="w-full h-full object-contain" 
                            />
                        </div>
                    ))}
                </div>
                {data.length > 10 && (
                    <div className="text-center mt-4">
                        <button
                            onClick={() => setShowAllPartners(!showAllPartners)}
                            className="px-4 py-2 border border-white text-white rounded-md hover:bg-white hover:text-black transition-colors duration-300 text-sm"
                        >
                            {showAllPartners ? 'Show Less' : 'View All Partners'}
                        </button>
                    </div>
                )}

                <div className="text-center my-6">
                    <p className="text-lg font-semibold text-white tracking-wide uppercase">
                        Trusted By Fuel Chain Project (Sway)
                    </p>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 gap-4 items-center justify-items-center">
                    {getVisibleItems(fuelChainData, showAllFuelChain).map((item) => (
                        <div key={item.id} className="w-20 h-20 flex items-center justify-center p-1">
                            <a href={item.url} target="_blank" rel="noopener noreferrer" className="w-full h-full">
                                <img 
                                    src={item.img} 
                                    alt={`Fuel Chain Logo ${item.id}`} 
                                    className="w-full h-full object-contain hover:opacity-80 transition-opacity" 
                                />
                            </a>
                        </div>
                    ))}
                </div>
                {fuelChainData.length > 10 && (
                    <div className="text-center mt-4">
                        <button
                            onClick={() => setShowAllFuelChain(!showAllFuelChain)}
                            className="px-4 py-2 border border-white text-white rounded-md hover:bg-white hover:text-black transition-colors duration-300 text-sm"
                        >
                            {showAllFuelChain ? 'Show Less' : 'View All Fuel Chain Partners'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TrustedBy;