import ReactDOM from 'react-dom/client';
import React from 'react'
import './assets/css/config.css'; // Importing config CSS
import './assets/css/global.css'; // Importing global CSS import './assets/css/AuditSection.css'; // Importing AuditSection CSS (this should match where the component is located)
import { RouterProvider } from 'react-router-dom'; // Router provider
import { router } from './router/router'; // Your custom router
// import AuditSection from './components/AuditSection'; // Importing the AuditSection component

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <RouterProvider router={router} /> 
    {/* <AuditSection /> This renders your AuditSection */}
  </>
);
