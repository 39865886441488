import React from "react";
import { Link } from "react-router-dom";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import logo from "../../assets/img/logo.png";
import fuelLogo from "../../assets/img/l8.svg";
import swayLogo from "../../assets/img/sway-logo.png";

const Footer = () => {
  return (
    <footer className="bg-dark-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border border-gray-700 rounded-2xl p-6 md:p-8 flex flex-col items-center md:items-start">
            <Link to="/" className="mb-6 transition-transform duration-300 hover:scale-110">
              <img 
                src={logo} 
                alt="Safe Edges Logo" 
                className="w-24 h-24 object-contain" 
                onError={(e) => {
                  e.target.style.display = 'none';
                  e.target.nextElementSibling.style.display = 'block';
                }}
              />
              <div className="hidden text-3xl font-bold text-center">SafeEdges</div>
            </Link>
            <p className="text-sm leading-relaxed text-center md:text-left">
              Empowering clients by proactively detecting and preventing criminal
              activities, ensuring the highest level of security and integrity
              before transactions are approved on the blockchain.
            </p>
          </div>

          <div className="border border-gray-700 rounded-2xl p-6 md:p-8 bg-gradient-to-br from-light-green-900 to-oliv-green-700">
            <h3 className="text-2xl font-bold mb-4">Discover Sway Language</h3>
            <img 
              src={swayLogo} 
              alt="Sway Language Logo" 
              className="w-16 h-16 mb-4 object-contain" 
              onError={(e) => {
                e.target.style.display = 'none';
                e.target.nextSibling.style.display = 'block';
              }}
            />
            <div className="hidden text-4xl font-bold mb-4">Sway</div>
            <p className="text-sm leading-relaxed mb-6">
              Sway is a domain-specific language (DSL) for the Fuel blockchain, designed 
              to enable developers to write safe and efficient smart contracts.
            </p>
            <a 
              href="https://fuellabs.github.io/sway/v0.66.2/book/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="bg-white-500 hover:dark-600 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
              Read More
            </a>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-700">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm mb-4 md:mb-0">
              © 2024 SafeEdges. All rights reserved.
            </p>
            <div className="flex items-center gap-6">
              <Link to="https://twitter.com/safe_edges" className="text-2xl hover:text-blue-400 transition duration-300">
                <FaTwitter />
              </Link>
              <Link to="https://t.me/bugtester25" className="text-2xl hover:text-blue-400 transition duration-300">
                <FaTelegramPlane />
              </Link>
              
              <div className="flex items-center gap-2">
                <span className="text-xs text-gray-400" style={{ opacity: 0.5 }}>Trusted by</span> {/* Light grey and dim text */}
                <img 
                  src={fuelLogo} 
                  alt="Trusted by Fuel"
                  className="w-24 object-contain" 
                  onError={(e) => {
                    e.target.style.display = 'none';
                    e.target.nextSibling.style.display = 'block';
                  }}
                />
              </div>

              <span className="hidden text-sm">Trusted by Fuel</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
